(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/shape-games-client/assets/javascripts/shape-games-client.js') >= 0) return;  svs.modules.push('/modules/oddset/components/shape-games-client/assets/javascripts/shape-games-client.js');
"use strict";

const {
  useCallback,
  useEffect,
  useState
} = React;
const {
  ShapeComponentConfig,
  ShapeComponentLoggingMap
} = svs.oddset.components.common.constants;
const logger = new svs.core.log.Logger('modules:oddset:shape-games-client');

const getSgInit = () => new Promise(resolve => {
  const checkSgInit = () => {
    setTimeout(() => {
      if (window.sgInit) {
        resolve(window.sgInit);
      } else {
        checkSgInit();
      }
    }, 0);
  };
  if (window.sgInit) {
    resolve(window.sgInit);
  } else {
    checkSgInit();
  }
});
const ShapeGamesClient = _ref => {
  let {
    version,
    componentName,
    shapeConfig
  } = _ref;
  const [sportsbook, setSportsbook] = useState(undefined);
  const initSportsbook = useCallback(async () => {
    const sgInit = await getSgInit();
    const getComponent = await sgInit({
      client: 'svs',
      environment: version
    });
    const ShapeSportsbook = await getComponent(ShapeComponentConfig[componentName]);
    setSportsbook(ShapeSportsbook);
  }, [version, componentName]);
  useEffect(() => {
    initSportsbook();
  }, [initSportsbook]);
  useEffect(() => {
    if (!sportsbook) {
      return;
    }
    const logToken = shapeConfig.props[ShapeComponentLoggingMap[componentName].logToken];
    logger.info("Starting Shape ".concat(componentName, " client"), logToken ? logToken : ', not logged in');
    sportsbook.render(shapeConfig);
  }, [sportsbook, componentName, shapeConfig]);
  return React.createElement("div", {
    className: "sportsbook-container",
    id: ShapeComponentConfig[componentName].divId
  });
};
setGlobal('svs.oddset.components.shape_games_client.ShapeGamesClient', ShapeGamesClient);

 })(window);